import React, { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import "antd/dist/antd.css";
import { Form, Input, Button, Alert } from "antd";
import { UserOutlined, LockOutlined, LoginOutlined } from "@ant-design/icons";
import SpeadivCompanyAndLogoiCON from "../assets/SpeadivCompanyAndLogo.js";

const LoginForm = ({ OnFinishCB, isRungUserOrPassword }) => {
  const [isLoading, setIsLoading] = useState(false);

  const isTinyScreen = useMediaQuery('(max-width:300px)');
  const isTinyScreenHeight_350 = useMediaQuery('(max-height:350px)');
  const isTinyScreenHeight = useMediaQuery('(max-height:801px)');
  const isMedumeScreen = useMediaQuery('(max-width:400px)');

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      await OnFinishCB(values["username"], values["password"]);
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishFailed = errorInfo => {};

  const wrongUserMsg = (
    <Form.Item
      wrapperCol={{
        span: 20,
      }}
    >
      <Alert
        message="There is a problem with the user or password, please try again"
        type="error"
        style={{
          textAlign: "center"
        }}
      />
    </Form.Item>
  );

  var ourWidth = "300px";
  if (isTinyScreen === true || isTinyScreenHeight === true) {
    ourWidth = "200px";
  } else if (isMedumeScreen === true) {
    ourWidth = "220px";
  }

  var innerStyle = {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "95vh",
    overflowY: isTinyScreenHeight ? "scroll" : null,
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "#ECEBEB",
        overflowY: isTinyScreenHeight ? "scroll" : "auto",
      }}
    >
      <div style={innerStyle}>
        <Form
          style={{ width: ourWidth, margin: isTinyScreenHeight_350 ? "5px auto" : "60px auto" }}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 20
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item style={{ margin: "50px auto" }}>
            <SpeadivCompanyAndLogoiCON style={{ margin: "50px auto" }} />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              span: 20,
            }}
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Username" />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              span: 20,
            }}
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              span: 20,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              icon={<LoginOutlined />}
              block
              loading={isLoading}
            >
              {isLoading ? null : 'Login'}
            </Button>
          </Form.Item>

          {isRungUserOrPassword && wrongUserMsg}

          
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;